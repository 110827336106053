//@ts-check

/**
 * @param {Object} query
 * @param  {string} query.baseUrl
 * @param  {string} query.id
 */
export async function fetchTokens({ baseUrl, id }) {
  let url = `${baseUrl}/api/stream/${id}/chromecast/?_t=${Date.now()}`;
  let response;
  let result;
  response = await fetch(url, {
    cache: "no-cache",
    headers: {
      "Content-Type": "application/json",
    },
  });
  result = await response.json();

  let drmToken = result.drm_token;
  let securityToken = result.security_token;

  return { drmToken, securityToken };
}

/**
 * Check if current token is still active
 * @param {Object} query
 * @param  {string} query.url
 * @param  {string} query.securityToken
 */
export function fetchSecurityToken({ url, securityToken }) {
  let _url = `${url}.json/?security_token=${securityToken}&_t=${Date.now()}`;
  return fetch(_url, {
    cache: "no-cache",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });
}
/**
 * @param {Object} query
 * @param  {string} query.retwitterBaseUrl
 * @param  {string} query.id
 */
export function fetchFeatures({ retwitterBaseUrl, id }) {
  let _url = `${retwitterBaseUrl}/public_api/v1/items/${id}/features/?_t=${Date.now()}`;
  return fetch(_url, {
    cache: "no-cache",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  })
    .then((res) => res.json())
    .then((res) => res.data.features);
}
