//@ts-check
import { Socket } from "phoenix";
const identity = (x) => x;

const castDebugLogger = cast.debug.CastDebugLogger.getInstance();
const TAG = "webSocket";

export default function webSocket({
  url,
  itemRefNumber,
  securityToken,
  onSessionStart,
  onSessionEnd,
}) {
  url =
    url.replace("http://", "ws://").replace("https://", "wss://") + "/socket";

  castDebugLogger.info(TAG, "webSocket finalURL: " + `${url}`);
  let socket = new Socket(url, { params: { userToken: "123" } });

  socket.connect();

  let itemsChannel = socket.channel(`item:${itemRefNumber}`, {
    security_token: securityToken,
  });
  castDebugLogger.info(TAG, "webSocket securityToken: " + securityToken);
  castDebugLogger.info(TAG, "webSocket itemRefNumber: " + itemRefNumber);

  onSessionStart = onSessionStart || identity;
  onSessionEnd = onSessionEnd || identity;

  itemsChannel.on("start_session", onSessionStart);
  itemsChannel.on("end_session", onSessionEnd);
  itemsChannel
    .join()
    .receive("ok", ({ messages }) =>
      castDebugLogger.info(TAG, "itemsChannel catching up: " + messages)
    )
    .receive("error", ({ reason }) =>
      castDebugLogger.info(TAG, "itemsChannel failed join: " + reason)
    )
    .receive("timeout", () =>
      castDebugLogger.info(
        TAG,
        "itemsChannel Networking issue. Still waiting..."
      )
    );

  return {
    socket,
    itemsChannel,
  };
}
