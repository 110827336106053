//@ts-check
import { fetchFeatures, fetchTokens, fetchSecurityToken } from "./js/http.js";
import webSocket from "./js/webSocket.js";

const context = cast.framework.CastReceiverContext.getInstance();
const castDebugLogger = cast.debug.CastDebugLogger.getInstance();
const playerManager = context.getPlayerManager();
// const playbackConfig = new cast.framework.PlaybackConfig();

const queryString = window.location.search;
const urlParams = new URLSearchParams(queryString);
const tvAppToken = decodeURIComponent(urlParams.get("tv-app-token"));

if (urlParams.get("debug") == "true") {
  // configure the cast debugger
  castDebugLogger.setEnabled(true);
}

if (urlParams.get("logs") == "true") {
  castDebugLogger.showDebugLogs(true);
}

castDebugLogger.loggerLevelByTags = {
  receiver: cast.framework.LoggerLevel.DEBUG,
};

castDebugLogger.loggerLevelByEvents = {
  "cast.framework.events.category.CORE": cast.framework.LoggerLevel.ERROR,
  // "cast.framework.events.EventType.MEDIA_STATUS":
  //   cast.framework.LoggerLevel.DEBUG,
};

// cast.receiver.logger.setLevelValue(cast.receiver.LoggerLevel.DEBUG);

const TAG = "RECEIVER";

playerManager.addEventListener(cast.framework.events.category.CORE, (event) => {
  if ((event.type = "ERROR")) {
    castDebugLogger.error(
      TAG,
      "cast.framework.events.category.CORE" + JSON.stringify(event)
    );
  }
});

// Update playback config licenseUrl according to provided value in load request.
playerManager.setMediaPlaybackInfoHandler(
  async (loadRequest, playbackConfig) => {
    /**
     * Set the player to start playback as soon as there are five seconds of
     * media content buffered. Default is 10.
     */
    playbackConfig.autoResumeDuration = 5;

    // castDebugLogger.info(
    //   TAG,
    //   "loadRequest.media.customData: " +
    //     JSON.stringify(loadRequest.media.customData)
    // );

    castDebugLogger.info(
      TAG,
      "playbackConfig.licenseUrl: " + playbackConfig.licenseUrl
    );
    // castDebugLogger.info(
    //   TAG,
    //   "loadRequest.media.customData.drmToken: " +
    //     loadRequest.media.customData.drmToken
    // );
    let { check_session_using_sockets } = await fetchFeatures({
      retwitterBaseUrl: loadRequest.media.customData.retwitterBaseUrl,
      id: loadRequest.media.customData.id,
    });

    let { drmToken, securityToken } = await fetchTokens({
      baseUrl: loadRequest.media.customData.baseUrl,
      id: loadRequest.media.customData.id,
    });

    castDebugLogger.info(TAG, "drmToken: " + drmToken);

    if (drmToken && drmToken != "") {
      playbackConfig.licenseUrl =
        "https://license.pallycon.com/ri/licenseManager.do";

      playbackConfig.protectionSystem =
        cast.framework.ContentProtection.WIDEVINE;

      playbackConfig.licenseRequestHandler = (requestInfo) => {
        // requestInfo.withCredentials = true;
        requestInfo.headers["pallycon-customdata-v2"] = drmToken;
        castDebugLogger.info(
          TAG,
          "licenseRequestHandler: " + JSON.stringify(requestInfo)
        );
        return requestInfo;
      };
    }

    if (check_session_using_sockets === true) {
      castDebugLogger.info(
        TAG,
        "!!!INIT SOCKETS SESSION CHECK TOKEN:" + tvAppToken
      );
      let { socket, itemsChannel } = webSocket({
        url: loadRequest.media.customData.retwitterBaseUrl,
        itemRefNumber: loadRequest.media.customData.id,
        securityToken: tvAppToken,
        onSessionStart: function () {
          castDebugLogger.info(TAG, "onSessionStart");
        },
        onSessionEnd: function () {
          castDebugLogger.info(TAG, "onSessionEnd");
          playerManager.stop();
        },
      });
    } else {
      scheduleChecks({
        url: loadRequest.media.customData.verifyUserUrl,
        securityToken,
      });
    }

    // let drmToken = loadRequest.media.customData.drmToken;

    // playbackConfig.licenseCustomData = drmToken;

    return playbackConfig;
  }
);

/**
 * Load interceptor
 */
playerManager.setMessageInterceptor(
  cast.framework.messages.MessageType.LOAD,
  (request) => {
    request.autoplay = true;

    request.media.contentId =
      request.media.customData.chromecastSrc ||
      request.media.contentId ||
      request.media.contentUrl;

    castDebugLogger.info(
      TAG,
      "request.media.contentId:" + request.media.contentId
    );

    return request;
  }
);

// playerManager.removeSupportedMediaCommands(
//   cast.framework.messages.Command.SEEK,
//   true
// );

/**
 * Seek a seek interceptor is called whenever CAF receives a request to seek to
 * a different location in media. This interceptor can be used to modify that
 * seek request or disable seeking completely.
 * @param {cast.framework.messages.MessageType.SEEK} The message to intercept.
 */
// playerManager.setMessageInterceptor(
//   cast.framework.messages.MessageType.SEEK,
//   (seekData) => {
//     // if the SEEK supported media command is disabled, block seeking
//     if (
//       !(
//         playerManager.getSupportedMediaCommands() &
//         cast.framework.messages.Command.SEEK
//       )
//     ) {
//       castDebugLogger.info(TAG, "Seek blocked.");
//       return null;
//     }

//     return seekData;
//   }
// );

context.start();
// context.start({ playbackConfig: playbackConfig });

// async function fetchTokens({ baseUrl, id }) {
//   let url = `${baseUrl}/api/stream/${id}/chromecast/?_t=${Date.now()}`;
//   castDebugLogger.info(TAG, "URL for token:" + url);
//   let response;
//   let result;
//   response = await fetch(url, {
//     // mode: "cors",
//     cache: "no-cache",
//     headers: {
//       "Content-Type": "application/json",
//     },
//   });
//   result = await response.json();

//   let drmToken = result.drm_token;
//   let securityToken = result.security_token;

//   castDebugLogger.info(TAG, "drmToken: " + drmToken);
//   castDebugLogger.info(TAG, "securityToken: " + securityToken);

//   return { drmToken, securityToken };
// }

function verifyUserToken({ url, securityToken }) {
  return fetchSecurityToken({ url, securityToken })
    .then((res) => {
      castDebugLogger.info(TAG, "res.status: " + res.status);
      if (res.status === 403) {
        playerManager.stop();
        return Promise.reject("403");
      }

      return res.json();
    })
    .then((res) => {
      castDebugLogger.info(TAG, "res text: " + JSON.stringify(res));
      scheduleChecks({ url, securityToken, timeout: res.timeout });
    })
    .catch((err) => {
      castDebugLogger.info(TAG, "catch: " + err);
    });
}

function scheduleChecks({ url, securityToken, timeout = 60000 }) {
  castDebugLogger.info(TAG, "timeout: " + timeout);
  const scheduleTimeout = Math.max(timeout, 60000);
  setTimeout(() => verifyUserToken({ url, securityToken }), scheduleTimeout);
}
